/// Demo and Debug servers:
// const LOCALHOST_SERVER = "127.0.0.1";
// const NTRADER_DEV_SERVER = '188.214.132.46';
// const NG_DEMO_2_SERVER = '169.50.175.11';
// const INDIAN_FEED = '188.214.133.87'

/// Old servers:
// const OLD_ZENITH_SERVER = '188.214.132.149';
// const ZENITH_SERVER = '169.50.185.249';
// const INFINITY_SEVER = '188.214.133.61';
// const DMA_CAPITALS_SERVER = '74.208.181.195';
// const X_WEB_TRADE = '188.214.133.154';
// const GALAXY_SERVER = '45.9.248.174';
// const FUTUREIX_SERVER = '188.214.132.176';

/// Working server
// const INVISOT_SERVER = '188.214.132.143';
const ROBOCHIP = '188.214.133.154';
// const ZENITH_PARTNER = ROBOCHIP;
// const ENVIFX = '5.79.91.202';
// const EQUINOX = '5.199.172.21';
// const FOCUS_CAPITAL = '188.214.132.204';
// const HEDDGE_CAPITALS = '188.214.133.144';

// Assign the corresponding server and port for the target built server
export const QUOTES_SERVER_IP = ROBOCHIP;
export const QUOTES_SERVER_PORT = 9001;

export const WEBSOCKET_NUM_RECONN_ATTEMPTS = 1000;
export const WEBSOCKET_HEART_BEAT_INTERVAL_MS = 10000;
export const ConnectionType = {
  MOBILE: 0,
  WEB: 1,
  CHART_VIEW: 8,
}
export const RequestCode = {
  HEART_BEAT: 0,
  REQ_LOGIN: 1,
  REQ_PRICE_HISTORY: 3,
}
export const ResponseCode = {
  HEART_BEAT: 0,
  LOGIN: 1,
  MARKET_PRICE: 2,
  PRICE_HISTORY: 3,
  ACCOUNT_INFO: 4,
  TRADE_HISTORY: 5,
  PLACED_ORDERS: 6,
  SYMBOL_LIST: 8,
}

export const DEFAULT_PERSISION = 5;
